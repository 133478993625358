import { IFacility } from "../reservations";
import { localize } from "../../localization";
import DisneyLandIcon from "./DisneyLandIcon";

export interface PassAvailabilityProps {
    availability: IFacility
}

function onlyCapitalLetters(str: string): string {
    return str.replace(/[^A-Z]+/g, "");
}

export function FacilityAvailability(props: PassAvailabilityProps) {
    const available: string = localize(props.availability.available ? "available" : "not-available");
    const altText: string = `${localize(props.availability.facilityName)} is ${available}`
    const text = onlyCapitalLetters(localize(props.availability.facilityName));

    const backgroundColour = props.availability.available ? "#0F0" : (props.availability.blocked ? "#888" : "#F00");

    return (
      <DisneyLandIcon altText={altText} text={text} backgroundColour={backgroundColour} height="45%" width="45%" />
    )
}