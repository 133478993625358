import { Day } from "../Day";
import { ICalendarAvailability, IFacility } from "../reservations";
import { localizeDay, localizeMonth } from "../../localization";
import styles from "./PassCalendar.module.css";
import { FacilityAvailability } from "../FacilityAvailability";

export interface PassAvailabilityProps {
    yearMonth: string
    availabilities: ICalendarAvailability[]
}

export function PassCalendar(props: PassAvailabilityProps) {
    const { yearMonth, availabilities } = props;

    const split = yearMonth.split("-");
    const month = split[split.length - 1];

    return (<div className={styles.calendarContainer}>
        <h1>{localizeMonth(month)}</h1>
        <div className={styles.calendar}>
            <div>{localizeDay("sun")}</div>
            <div>{localizeDay("mon")}</div>
            <div>{localizeDay("tue")}</div>
            <div>{localizeDay("wed")}</div>
            <div>{localizeDay("thu")}</div>
            <div>{localizeDay("fri")}</div>
            <div>{localizeDay("sat")}</div>
        </div>
        <div className={styles.calendar}>
            {availabilities.map((value: ICalendarAvailability, index: number) => (
                <Day key={value.date} availability={value} first={index === 0}>
                    <div className={styles.day}>
                        {value.facilities.map((value: IFacility) => (<FacilityAvailability key={value.facilityName} availability={value} />))}
                    </div>
                </Day>
            ))}
        </div>
    </div>);
}