
import { CSSProperties, PropsWithChildren } from "react";
import { ICalendarAvailability } from "../reservations";
import styles from "./Day.module.css";

// function getLang(): string {
// 	if (navigator.languages !== undefined)
// 		return navigator.languages[0];
// 	return navigator.language;
// }

export interface ReservationDayProps {
	availability: ICalendarAvailability
	first: boolean
}

export const Day = (props: PropsWithChildren<ReservationDayProps>) => {
	const { availability, first } = props;

	const date = new Date(Date.parse(availability.date))

	const style: CSSProperties = first ? {gridColumn: `${date.getUTCDay()+1}`} : {};

	return (
		<div className={styles.day} style={style}>
			<time dateTime={availability.date}>{date.getUTCDate()}</time>
			<div>
				{props.children}
			</div>
		</div>
	);
};