import { CSSProperties } from "react";

export interface DisneyLandIconProps {
  backgroundColour: string
  altText: string
  width: string
  height: string
  text: string
  style?: CSSProperties
}

function DisneyLandIcon(props: DisneyLandIconProps) {
  const viewBox = `0 0 200 200`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <title>{props.altText}</title>
      <g stroke="#000">
        <ellipse
          cx="50%"
          cy="50%"
          fill={props.backgroundColour}
          strokeWidth="2.5%"
          rx="48%"
          ry="48%"
        ></ellipse>
        <text
          x="50%"
          y="50%"
          fill="#000"
          strokeWidth="5"
          fontSize="100"
          transform="scale(1 1)"
          textAnchor="middle"
          dominantBaseline="middle"
          stroke="#020202"
          fontFamily="Arial"
    >
            {props.text}
        </text>
      </g>
    </svg>
  );
}

export default DisneyLandIcon;
