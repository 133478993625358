import './App.css';
import ReservationAvailability from './reservations/ReservationAvailability'

function App() {
  return (
    <div>
        <ReservationAvailability />
    </div>
  );
}

export default App;
