import { ChangeEvent, useCallback, useEffect, useReducer } from 'react';
import { localize } from "../localization";

import { ReservationStateUpdate, reservationStateReducer } from './reservationState';
import { PassCalendar } from './PassCalendar';
import { IMonthlyAvailability } from './reservations';
import styles from './ReservationAvailability.module.css';

function ReservationAvailability() {
	var [state, dispatch] = useReducer(reservationStateReducer, {});

	useEffect(() => {
		const fetchPassTypes = async () => {
			try {
				const response = await fetch(`/api/pass-types`);
				const jsonData = await response.json();
				dispatch({ type: ReservationStateUpdate.UPDATE_PASSTYPES, payload: jsonData });
			} catch (error) {
				console.log('Error:', error);
			}
		}
	
		fetchPassTypes();
	}, [])

	useEffect(() => {
		if (state.currentPassType)
		{
			const fetchData = async () => {
				try {
					const response = await fetch(`/api/reservation-availabilities?passType=${state.currentPassType}&months=2`);
					const jsonData = await response.json();
					dispatch({ type: ReservationStateUpdate.UPDATE_DISNEYDATA, payload: jsonData });
				} catch (error) {
					console.log('Error:', error);
				}
			};

			fetchData();
		}
	}, [state.currentPassType]);

	const handleRadioChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
		dispatch({ type: ReservationStateUpdate.UPDATE_SELECTED_PASSTYPE, payload: ev.currentTarget.value });
	}, [])

	return (
		<div className={styles.container}>
			{state.passTypes ? (
				<div>
					<h1>{localize("select-pass-type")}</h1>
					{state.passTypes.map((value: string) => (
						<div className={styles.options} key={value}>
							<input name="passType" type="radio" id={value} value={value} onChange={handleRadioChange} checked={state.currentPassType === value}/>
  							<label htmlFor={value}>{localize(value)}</label>
						</div>
					))}
					<h1>{localize(state.currentPassType ?? "")}</h1>
					<div>
						{state.currentAvailability ? (state.currentAvailability.availability.map((value: IMonthlyAvailability) => (<PassCalendar key={value.month} yearMonth={value.month} availabilities={value.availability} />))) : (<div>Loading...</div>)}
					</div>
				</div>
			) : (<div>Loading...</div>)}
		</div>
	);
}

export default ReservationAvailability;
