import { IReservationAvailability, PassTypes } from "./reservations"

export interface ReservationState {
    currentAvailability?: IReservationAvailability
    currentPassType?: string
    passTypes?: PassTypes
}

export enum ReservationStateUpdate {
    UPDATE_DISNEYDATA = 'UPDATE_DISNEYDATA',
    UPDATE_SELECTED_PASSTYPE = 'UPDATE_SELECTED_PASSTYPE',
    UPDATE_PASSTYPES = 'UPDATE_PASSTYPES'
}

export interface ReservationAction {
    type: ReservationStateUpdate
    payload: IReservationAvailability | string | PassTypes
}

export function reservationStateReducer(state: ReservationState, action: ReservationAction): ReservationState {
    const { type, payload } = action;
    switch (type) {
        case ReservationStateUpdate.UPDATE_DISNEYDATA:
            const updatePayload: IReservationAvailability = payload as IReservationAvailability;

            if (updatePayload === null) {
                return {};
            }

            return {
                ...state,
                currentAvailability: updatePayload,
            };
        case ReservationStateUpdate.UPDATE_SELECTED_PASSTYPE:
            const passType: string = payload as string;
            return {
                ...state,
                currentAvailability: undefined,
                currentPassType: passType,
            };

        case ReservationStateUpdate.UPDATE_PASSTYPES:
            const passTypes: PassTypes = payload as PassTypes;
            return {
                ...state,
                currentPassType: state.currentPassType ?? passTypes[0],
                passTypes: passTypes
            }
        default:
            return state;
    }
}