

export type Localizations = {
    [K: string]: string,
}


const LocalizationStrings: Localizations = {
    'inspire-key-pass': 'Inspire Key Pass',
    'believe-key-pass': 'Believe Key Pass',
    'enchant-key-pass': 'Enchant Key Pass',
    'imagine-key-pass': 'Imagine Key Pass',
    'dream-key-pass': 'Dream Key Pass',
    'DLR_DP': 'Disneyland Park',
    'DLR_CA': 'California Adventure',
    'available': "Available",
    'not-available': "Not Available",
    'select-pass-type': "Select Pass Type",
}

export function localize(key: string): string {
    return LocalizationStrings[key] ?? key;
}

const MonthLocalizationStrings: Localizations = {
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December",
}

export function localizeMonth(month: string): string {
    return MonthLocalizationStrings[month];
}

const DayLocalizationStrings: Localizations = {
    "sun": "Sun",
    "mon": "Mon",
    "tue": "Tue",
    "wed": "Wed",
    "thu": "Thu",
    "fri": "Fri",
    "sat": "Sat",
    "sunday": "Sunday",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
}

export function localizeDay(day: string): string {
    return DayLocalizationStrings[day];
}